@font-face {
	font-family: 'SourceSansPro';
	src: url(../fonts/sourcesans/SourceSansPro-Regular.woff2) format('woff2'),
		url(../fonts/sourcesans/SourceSansPro-Regular.woff) format('woff'),
		url(../fonts/sourcesans/SourceSansPro-Regular.otf) format("opentype");
	font-weight: 400;
}

@font-face {
	font-family: 'SourceSansPro';
	src: url(../fonts/sourcesans/SourceSansPro-Bold.woff2) format('woff2'),
		url(../fonts/sourcesans/SourceSansPro-Bold.woff) format('woff'),
		url(../fonts/sourcesans/SourceSansPro-Bold.otf) format("opentype");
	font-weight: 700;
}

@font-face {
	font-family: 'SourceSansPro';
	src: url(../fonts/sourcesans/SourceSansPro-Semibold.woff2) format('woff2'),
		url(../fonts/sourcesans/SourceSansPro-Semibold.woff) format('woff'),
		url(../fonts/sourcesans/SourceSansPro-Semibold.otf) format("opentype");
	font-weight: 600;
}


@font-face {
	font-family: 'TitilliumUpright';
	src: url(../fonts/titillium/Titillium-BoldUpright.woff2) format('woff2'),
		url(../fonts/titillium/Titillium-BoldUpright.woff) format('woff'),
		url(../fonts/titillium/Titillium-BoldUpright.otf) format("opentype");
	font-weight: 700;
}

@font-face {
	font-family: 'TitilliumUpright';
	src: url(../fonts/titillium/Titillium-SemiboldUpright.woff2) format('woff2'),
		url(../fonts/titillium/Titillium-SemiboldUpright.woff) format('woff'),
		url(../fonts/titillium/Titillium-SemiboldUpright.otf) format("opentype");
	font-weight: 400;
}

@font-face {
	font-family: 'TitilliumUpright';
	src: url(../fonts/titillium/Titillium-LightUpright.woff2) format('woff2'),
		url(../fonts/titillium/Titillium-LightUpright.woff) format('woff'),
		url(../fonts/titillium/Titillium-LightUpright.otf) format("opentype");
	font-weight: 200;
}

/**
Screen Reader only used in component ScreenReaderOnly
**/

.sr-only:not(:focus):not(:active) {
	clip: rect(0 0 0 0); 
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap; 
	width: 1px;
  }